import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useApi from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalHeader";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";
import { LoadingButton } from "@mui/lab";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { Audio } from "react-loading-icons";
import countries from "../../../utils/countries";
import getCurrentDate from "../../../utils/getCurrentDate";

export default function VistaADashboard() {
  const { loadApi, loadingApi } = useApi();
  const { songId } = useParams();
  const { user } = useUser();
  const [mainData, setMainData] = useState(false);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();

  useEffect(() => {
    loadApi(`productor/canciones/${songId}`, true, "get")
      .then((response) => {
        setMainData(response.data || false);
      })
      .catch((e) => {
        showSnack(e.message, "error", false);
      });
  }, []);

  function updateSend(status) {
    loadApi(`productor/envios/${songId}`, true, "put", { status })
      .then((response) => {
        showSnack(response.data.message);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      });
  }

  return (
    <>
      <GlobalHeader />
      {!mainData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: `calc(100vh - ${
              window.document.getElementById("GlobalHeader")?.offsetHeight ||
              "64"
            }px - 32px)`,
          }}
        >
          <Audio fill="#F27224" />
        </Box>
      ) : (
        <Container>
          <Grid container spacing={2} mb={5}>
            {/* COLUMNA IZQUIERDA */}
            <Grid item xs={12} md={7} lg={7}>
              <Stack spacing={2}>
                <Typography
                  variant="h4"
                  sx={{
                    paddingBottom: 1,
                    borderBottom: "2px solid gray",
                  }}
                >
                  Preview de tu canción
                </Typography>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Box sx={{ width: { xs: "100%", lg: "93%" } }}>
                      <img
                        src={mainData.url_img}
                        style={{ borderRadius: "4px/6.7px" }}
                        width="100%"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={1}>
                      <Typography variant="h5">${mainData.radarCoin}</Typography>
                      <Stack direction="row" flexWrap="wrap">
                        <Typography variant="h6">
                          {user.profile.nombre_media} - "{mainData.nombre}"
                        </Typography>
                      </Stack>
                      <Typography variant="p">
                        Fecha de envio: {getCurrentDate()}
                      </Typography>
                      <Typography variant="p">
                        País: {countries[mainData.pais]}
                      </Typography>
                      <Typography variant="p">
                        Género: {mainData.genero}
                      </Typography>
                      <img src="/assets/clipboard.svg" width={200} />
                    </Stack>
                  </Grid>
                </Grid>
                <Typography variant="h5">Descripción de la canción</Typography>
                <Typography variant="p">{mainData.descripcion}</Typography>
                <Typography variant="h5">Perfil</Typography>
                <Typography variant="p">{user.profile.descripcion}</Typography>
              </Stack>
            </Grid>
            {/* COLUMNA DERECHA */}
            <Grid item xs={12} md={5} lg={5}>
              <Stack spacing={3} px={3}>
                <Stack alignItems="center">
                  <img
                    src={mainData.url_img}
                    style={{ borderRadius: "4px/6.7px" }}
                    width="70%"
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={1.3}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" align="left">
                    Obtener codigo embed
                  </Typography>
                  <Button color="info">Copiar</Button>
                </Stack>
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{
                    borderBottom: "2px dashed gray",
                  }}
                ></Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <LoadingButton
                    loading={loadingApi.includes(
                      `put__productor/envios/${songId}`
                    )}
                    onClick={() => updateSend("approve")}
                    color="success"
                  >
                    Aprobar canción
                  </LoadingButton>
                  <LoadingButton
                    loading={loadingApi.includes(
                      `put__productor/envios/${songId}`
                    )}
                    onClick={() => updateSend("rejected")}
                    color="error"
                  >
                    Rechazar canción
                  </LoadingButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      )}
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}
